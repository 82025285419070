* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

/**
  * PurgeCSS:
  *
  * BACKGROUND COLOR
  *
  * bg-rose-300
  * bg-rose-600
  * bg-rose-900
  * bg-fuchsia-300
  * bg-fuchsia-600
  * bg-fuchsia-900
  * bg-violet-300
  * bg-violet-600
  * bg-violet-900
  * bg-sky-300
  * bg-sky-600
  * bg-sky-900
  * bg-green-300
  * bg-green-600
  * bg-green-900
  * bg-cyan-300
  * bg-cyan-600
  * bg-cyan-900
  * bg-teal-300
  * bg-teal-600
  * bg-teal-900
  * bg-emerald-300
  * bg-emerald-600
  * bg-emerald-900
  * bg-lime-300
  * bg-lime-600
  * bg-lime-900
  * bg-orange-300
  * bg-orange-600
  * bg-orange-900
  * bg-red-300
  * bg-red-600
  * bg-red-900
  * bg-yellow-300
  * bg-yellow-600
  * bg-yellow-900
  * bg-blue-300
  * bg-blue-600
  * bg-blue-900
  * bg-indigo-300
  * bg-indigo-600
  * bg-indigo-900
  * bg-purple-300
  * bg-purple-600
  * bg-purple-900
  * bg-pink-300
  * bg-pink-600
  * bg-pink-900
  *
  * ==================================================================
  * 
  * BORDER COLOR
  * 
  * border-rose-300
  * border-rose-600
  * border-rose-900
  * border-fuchsia-300
  * border-fuchsia-600
  * border-fuchsia-900
  * border-violet-300
  * border-violet-600
  * border-violet-900
  * border-sky-300
  * border-sky-600
  * border-sky-900
  * border-green-300
  * border-green-600
  * border-green-900
  * border-cyan-300
  * border-cyan-600
  * border-cyan-900
  * border-teal-300
  * border-teal-600
  * border-teal-900
  * border-emerald-300
  * border-emerald-600
  * border-emerald-900
  * border-lime-300
  * border-lime-600
  * border-lime-900
  * border-orange-300
  * border-orange-600
  * border-orange-900
  * border-red-300
  * border-red-600
  * border-red-900
  * border-yellow-300
  * border-yellow-600
  * border-yellow-900
  * border-blue-300
  * border-blue-600
  * border-blue-900
  * border-indigo-300
  * border-indigo-600
  * border-indigo-900
  * border-purple-300
  * border-purple-600
  * border-purple-900
  * border-pink-300
  * border-pink-600
  * border-pink-900
  *
  * =================================================================
  * 
  * BORDER STYLE
  *
  * border-solid
  * border-dotted
  * border-dashed
  * border-double
  *
  * ==================================================================
  *
  * OTHER
  *
  * opacity-50 
  * duration-200 
  * opacity-100
  * text-green-500
  * text-red-500
*/
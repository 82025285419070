@layer components {
  .button {
    @apply font-bold p-2 rounded-md;
  }
  .button-yellow {
    @apply bg-yellow-300 hover:bg-yellow-400 focus:bg-yellow-500 text-yellow-900;
  }
  .button-green {
    @apply bg-green-300 hover:bg-green-400 focus:bg-green-500 text-green-900;
  }
  .button-red {
    @apply bg-red-300 hover:bg-red-400 focus:bg-red-500 text-red-900;
  }
  .button-sky {
    @apply bg-sky-800 hover:bg-sky-600 focus:bg-sky-400 text-white
  }
  .infoBlock {
    @apply bg-gray-200 hover:bg-gray-300 rounded-md px-2 m-2 font-bold flex items-center justify-center;
  }
  .panelWidth {
    @apply w-full lg:w-2/3 xm:w-2/4 3xl:w-2/5;
  }
  .lobbyOptInput {
    @apply p-1 text-black bg-white rounded-md;
  }
}

.userLoading {
  animation-duration: 1.8s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: UserLoadingAnimation;
  animation-timing-function: linear;
  background: #fca5a5;
  background: linear-gradient(to right, #fca5a5 8%, #fecaca 38%, #fca5a5 8%);
  background-size: 800px 104px;
  position: relative;
}

@keyframes UserLoadingAnimation {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}

.copyCode {
  position: relative;
  display: inline-block;
}

.copyCode .toolTipText {
  visibility: hidden;
  width: 140px;
  background-color: #e5e7eb;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: -75px;
  left: 45px;
  margin-left: -75px;
  opacity: 0;
  transition: opacity 0.3s;
}

.copyCode .toolTipText::after {
  content: "";
  position: absolute;
  top: -10px;
  left: 70px;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #e5e7eb transparent;
}

.copyCode:hover .toolTipText {
  visibility: visible;
  opacity: 1;
}

.failConnect {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  position: fixed;
  z-index: 1000;
  width: 100vw;
  height: 100vh;
  background: black;
  animation: failConnectAnimation 2.5s normal 0s 1;
}

.failConnect p, svg, text {
  color: white;
  text-shadow: white 0 0 12px;
}

@keyframes failConnectAnimation {
  0% {
    background: rgba(0, 0, 0, 0.25);
  }
  100% {
    background: black;
  }
}

@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

:root {
  --scrollbar: rgb(54, 61, 71);
  --scrollbar-thumb: rgb(160, 170, 184);
  --scrollbar-thumb-hover: rgb(182, 192, 206);
  --scrollbar-thumb-active: rgb(219, 225, 233);
  --scrollbarInactiveAction: rgb(134, 134, 134);
}

html,
body,
#root,
.App {
  width: 100%;
  min-height: 100%;
  height: 100%;
  display: flow-root;
  overflow: auto;
  user-select: none;
  overscroll-behavior: contain;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  scrollbar-color: var(--scrollbar-thumb) var(--scrollbar);
  scrollbar-width: thin;
  scroll-behavior: smooth;
  outline: none;
}

::-webkit-scrollbar {
  width: 14px;
}

::-webkit-scrollbar-track {
  background-color: var(--scrollbar);
}

::-webkit-scrollbar-thumb {
  background-color: var(--scrollbar-thumb);
  border-radius: 10px;
  border: 4px solid #00000000;
  background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
  background-color: var(--scrollbar-thumb-hover);
}
::-webkit-scrollbar-thumb:active {
  background-color: var(--scrollbar-thumb-active);
}
::-webkit-scrollbar-corner {
  background-color: var(--scrollbar);
}
::-webkit-scrollbar-track-piece {
  background-color: var(--scrollbar);
}
::-webkit-resizer {
  background-color: var(--scrollbar);
}

::-webkit-scrollbar-thumb:window-inactive {
  background-color: var(--scrollbarInactiveAction);
}

::selection {
  color: rgb(55, 0, 71);
  background: rgb(227, 203, 255);
}
